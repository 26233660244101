import React from 'react';
import { Link, Outlet } from "react-router-dom";


const NavBar = () => {
    return (
        <>
            <nav className='navbar'>
                <div className="navbar--title">
                    <h1>Code BluePrint</h1>
                </div>
                    <ul className="navbar--item">
                        <li>
                            <Link to="/"><i className="fa-solid fa-house"></i><span>Accueil</span></Link>
                        </li>
                        <li>
                            <Link to="/Categories"><i className="fa-solid fa-code"></i><span>Categories</span></Link>
                        </li>
                    </ul>

            </nav>
            <Outlet />
        </>
    );
}

export default NavBar;
