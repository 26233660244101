import React from 'react'
import { useParams } from "react-router-dom";
import useFetch from './useFetch'
import Card from './Card'


export default function Tags() {

  const [data] = useFetch("../data.json")
  let { tags } = useParams()

  return (
    <>
    <h1 className='title'>{tags}</h1>
      {data && data.map((item, index) => {
        if (typeof item !== undefined && item.results) {
          return (
            <div key={`cat${index}`} className="tags">
              {item && item.results?.map((res, index) => {
                if (typeof res !== undefined && res.category === tags) {
                  return (
                    <Card key={res.id} id={res.id} name={res.name} cover={res.cover} lang={res.details.languages} cdn={res.details.cdn} />
                  )
                }
              })}
            </div>
          )
        } else {
          null
        }
      })}
    </>
  )
}