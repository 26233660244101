import React from 'react'

export default function HomePage() {
    return (
        <div className='homepage'>
            <div className="homepage-landing">
                <p>Réaliser en React, ce projet a pour but de compiler les différents composents que j'ai utiliser sur mes différents projets lors de mon cursus de formation en tant que Développeur Web.</p>
            </div>
            {/* <div className="homepage-content">
                <div className="content-bloc">
                    <div className="content-detail">
                        <div className="content-detail-title bloc-solid">
                            <h2>Presentation</h2>
                        </div>
                        <span className='connect-line'></span>
                        <div className="content-detail-text bloc-dotted">
                            <p>Visualiser les différents langages et technologies pour chaques composents.</p>
                        </div>
                    </div>
                </div>
                <svg width="100%" height="200px">
                    <line x1="10%" y1="0" x2="10%" y2="100" stroke="white" strokeWidth="4px"></line>
                    <line x1="10%" y1="100" x2="90%" y2="100" stroke="white" strokeWidth="4px"></line>
                    <line x1="90%" y1="100" x2="90%" y2="200" stroke="white" strokeWidth="4px"></line>
                </svg>
                <div className="content-bloc">
                    <div className="content-detail">
                        <div className="content-detail-text bloc-dotted">
                            <p>Prévisualiser le rendu de chaques composents.</p>
                        </div>
                        <span className='connect-line'></span>
                        <div className="content-detail-title bloc-solid">
                            <h2>Preview</h2>
                        </div>
                    </div>
                </div>
                <svg width="100%" height="200px">
                    <line x1="90%" y1="0" x2="90%" y2="100" stroke="white" strokeWidth="4px"></line>
                    <line x1="10%" y1="100" x2="90%" y2="100" stroke="white" strokeWidth="4px"></line>
                    <line x1="10%" y1="100" x2="10%" y2="200" stroke="white" strokeWidth="4px"></line>
                </svg>
                <div className="content-bloc">
                    <div className="content-detail">
                        <div className="content-detail-title bloc-solid">
                            <h2>Codes</h2>
                        </div>
                        <span className='connect-line'></span>
                        <div className="content-detail-text bloc-dotted">
                            <p>Retrouver le code html / css / js détaillés pour chaques composents.</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
