import { Routes, Route } from "react-router-dom";
import './assets/styles/App.css';
import Categories from "./components/Categories";
import Tags from "./components/Tags";
import CodeShow from "./components/CodeShow";
import HomePage from "./components/HomePage";
import NavBar from "./components/NavBar";

function App() {
  return (
    <div className="App">
      <NavBar />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Categories" element={<Categories />} />
          <Route path="/Categories/:tags" element={<Tags />} />
          <Route path="/Code/:id" element={<CodeShow />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
