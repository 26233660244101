import React from 'react'
import { useParams } from 'react-router-dom'
import CodeDisplay from './CodeDisplay'
import useFetch from './useFetch'


export default function CodeShow() {


  const [data] = useFetch("../data.json")
  let { id } = useParams()

  return (
    <>
      {data && data.map((item, index) => {
        if (typeof item !== undefined && item.results) {
          return (
            <div key={index} className="codes">
              {/* {console.log(item.results)} */}
              {item.results?.map((res) => {
                if (res.id === id) {
                  return (
                    <div key={res.id} className="code">
                      <div className="code-detail">
                        <div className="code-detail-lang">
                          <h2>Languages :</h2>
                          <ul>
                            {res.details.languages.map((lang) => {
                              return (
                                <li key={lang.id}><img src={`../${lang.logo}`} alt="" /></li>
                              )
                            })}
                          </ul>
                        </div>
                        <div className="code-cdn">
                          <h2>CDN :</h2>
                          <ul>
                            {res.details.cdn.map((c) => {
                              return (
                                <li key={c.id}><img src={`../${c.logo}`} alt="" /></li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="code-preview">
                        <img src={`../${res.cover}`} alt={`preview de ${res.name}`} />
                      </div>
                      <div className="code-display">
                        <CodeDisplay html={res.codes.html} css={res.codes.css} js={res.codes.js} />
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          )
        }
      })}
    </>
  )
}
