import React from 'react'
import useFetch from './useFetch'
import { Link } from "react-router-dom";


export default function Categories() {

    const [data] = useFetch("data.json")
    return (
        <div className='cat'>
            <h1 className='title'>Categories</h1>
            {data && data.map((item, index) => {
                if (typeof item !== undefined && item.categories) {
                    return (
                        <div className="categories" key={index}>
                            {item.categories?.map((cat) => {
                                return (
                                    <div className="categorie" key={cat.id}>
                                        <Link to={`${cat.name}`} className="categorie-link" >{cat.name}</Link>
                                    </div>
                                )
                            })}
                        </div>
                    )
                } else {
                    null
                }
            })}
        </div>
    )
}

                    // <div className="categories-card">
                    //     <Link to={` ${cat.categories}`}>{cat.categories}</Link>
                    // </div>


                //     <div key={`a${index}`} className="categories-box">
                //     {item.categories?.map((cat, index) => {
                //         return (
                //             <div key={`b${index}`} className="categories-card">
                //                 <Link to={`${cat.name}`} >{cat.name}</Link>
                //             </div>
                //         )
                //     })}
                // </div>