import React from 'react'
import { Link } from "react-router-dom";


export default function Card(props) {
    return (
        <div key={props.id} className="card">
            <div className="card-image">
                <img src={`../${props.cover}`} alt={`preview de ${props.name}`} />
            </div>
            <div className="card-block">
                <div className="card-content">
                    <ul className='card-content-lang'>
                        {props.lang?.map((lang) => {
                            return (
                                <li key={lang.id}>
                                    <img src={`../${lang.logo}`} alt={`logo ${lang.name}`} />
                                </li>
                            )
                        })}
                    </ul>
                    <ul className='card-content-cdn'>
                        {props.cdn?.map((cdn) => {
                            return (
                                <li key={cdn.id}>
                                    <img src={`../${cdn.logo}`} alt={`logo ${cdn.name}`} />
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="card-link">
                    <Link to={`/Code/${props.id}`}>Detail</Link>
                </div>
            </div>
        </div>
    )
}
