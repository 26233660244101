import React, { useState } from 'react'
import Button from './Button';

export default function CodeDisplay(props) {

    const [openedEditor, setOpenedEditor] = useState('html');

    const onTabClick = (editorName) => {
        setOpenedEditor(editorName);
    };
    
    return (
        <div>
            <div className="button-editor">
                <Button title="HTML" onClick={() => {
                    onTabClick('html')
                }} />
                <Button title="CSS" onClick={() => {
                    onTabClick('css')
                }} />
                <Button title="Js" onClick={() => {
                    onTabClick('js')
                }} />
            </div>
            <div className="editor">
                {
                    openedEditor === 'html' ? (
                        <div>
                            {props.html.map((html, index) => {
                                return (
                                    <div key={index} className='editor-code editor-code__html'>
                                        <code >{html}</code>
                                        <br />
                                    </div>)
                            })
                            }
                        </div>
                    ) : openedEditor === 'css' ? (
                        <div>
                            {props.css.map((css, index) => {
                                return (
                                    <div key={index} className='editor-code editor-code__css'>
                                        <code >{css}</code>
                                        <br />
                                    </div>
                                )
                            })
                            }
                        </div>
                    ) : (
                        <div>
                            {props.js.map((js, index) => {
                                return (
                                    <div key={index} className='editor-code editor-code__js'>
                                        <code >{js}</code>
                                        <br />
                                    </div>)
                            })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}
